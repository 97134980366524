import React, { FunctionComponent } from "react";
import "./ParticipantTile.scss";
import CustomAvatar from "../CustomAvatar/CustomAvatar";
import NameTag from "../NameTag/NameTag";
import frame from "../../Resources/InCallImages/frame.svg";
import { useSelector } from "react-redux";
import { getTranslation } from "../../Resources/Localization/i18n";

export type ParticipantTileType = {
  className?: string;
  tileSize?: { tileHeight: number; tileWidth: number };
  participant?: any;
  smallTile?: boolean;
  screenShared?: boolean;
  notToUseRatio?: boolean;
};

const ParticipantTile: FunctionComponent<ParticipantTileType> = ({
  className = "",
  tileSize,
  participant,
  smallTile,
  screenShared,
  notToUseRatio,
}) => {
  // console.log("+++++++++++++++++++++ ", participant, tileSize, smallTile, screenShared, notToUseRatio, className);
  const videoPermission = useSelector(
    (state: any) => state.InCallReducer.videoPermission
  );

  return (
    <div
      className={
        screenShared
          ? `screentile ${className}`
          : `participanttile ${className}`
      }
      style={{
        width: screenShared || notToUseRatio ? "100%" : tileSize?.tileWidth,
        height: screenShared || notToUseRatio ? "100%" : tileSize?.tileHeight,
      }}
    >
      <NameTag
        participantName={
          participant.isLocal
            ? `${participant.identity}${getTranslation("you")}`
            : participant.identity
        }
        showMicOffIcon={!participant?.audioTracks[0]?.isEnabled}
      />
      {participant?.videoTracks[0]?.isEnabled &&
      !screenShared
      ? (
        <video
          className="videoframe"
          id={`video-${participant?.sid}`}
          autoPlay
          playsInline
          muted
        />
      ) : (
        (!screenShared || (participant.isLocal && !videoPermission)) && (
          <div className="novideoframeavatar">
            <CustomAvatar
              name={participant?.identity}
              hideName={true}
              size={smallTile ? "30" : undefined}
            />
          </div>
        )
      )}
      {participant?.videoTracks[1]?.isEnabled && screenShared && !smallTile && (
        <video
          className={
            window.innerHeight > window.innerWidth
              ? "portrait-screen videoframe"
              : "landscape-screen videoframe"
          }
          id={`screen-${participant?.sid}`}
          autoPlay
          playsInline
          muted
        />
      )}
      <div className="minimiseicon">
        <img className="frame-icon" alt="" src={frame} />
      </div>
    </div>
  );
};

export default ParticipantTile;
