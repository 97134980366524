import { FunctionComponent, useEffect } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as SelectedVideoDevice } from "../../Resources/InCallImages/DeviceSelection/SelectVideoDevice.svg";
import { ReactComponent as SelectionOptionIcon } from "../../Resources/InCallImages/DeviceSelection/SelectionOptionIcon.svg";
// import { ReactComponent as BackgroundBlurIcon } from "../../Resources/InCallImages/DeviceSelection/backgroundbluricon.svg";
// import { ReactComponent as DownArrow } from "../../Resources/InCallImages/DeviceSelection/downarrowicon.svg";
// import { ReactComponent as UpArrow } from "../../Resources/InCallImages/DeviceSelection/arrowicon.svg";
import { store } from "../../Redux/store";
import { delay } from "../../Utility/Utils";
import { setErrorNotification } from "../../Redux/Actions/NotificationAction";
import { getTranslation } from "../../Resources/Localization/i18n";
import proConfService from "../../Services/ProConfService";
import "./VideoDeviceSelection.scss";

export type VideoDeviceSelectionType = {
  className?: string;
  onClose?: () => void;
};

const VideoDeviceSelection: FunctionComponent<VideoDeviceSelectionType> = ({
  className = "",
  onClose,
}) => {
  const videoDevices =
    useSelector((state: any) => state.InCallReducer.videoDevices) ||
    proConfService.getVideoDevices;

  const selectedCamera = useSelector(
    (state: any) => state.ToolbarReducer.selectedCamera
  );

  const localParticipant = useSelector(
    (state: any) => state.ParticipantReducer.localParticipant
  );

  useEffect(() => {
    if (videoDevices?.length === 0) {
      proConfService.getVideoDevices();
    }
  }, []);

  // Restart the local camera track with updated deviceID
  const handleCameraSelection = async (device: any) => {
    store.dispatch({ type: "SET_SELECTED_CAMERA", payload: device });
    if (device?.deviceId && selectedCamera?.deviceId !== device?.deviceId) {
      try {
        console.log('localParticipant?.videoTracks:', localParticipant?.videoTracks, device?.deviceId);
        const videoTrack = await localParticipant.videoTracks[0]?.restart({
          deviceId: { exact: device.deviceId },
        });
        console.log('videoTrack:', videoTrack);
        if (videoTrack) {
          videoTrack.attach(
            document.getElementById(
              "video-" + localParticipant?.sid
            ) as HTMLVideoElement
          );
        } else {
          setErrorNotification(getTranslation('cameraSwitchError'));
        }
      } catch (error) {
        console.log(error);
        setErrorNotification(getTranslation('cameraSwitchError'));
      }
    }
  };

  return (
    <div className={`videodeviceselection ${className}`}>
      <div className="selectvideodevicecontainer">
        <div className="selectvideodevicetitlebar">
          <SelectedVideoDevice className="controliconsmall" />
          <div className="select-video-device">{getTranslation("selectVideoDevice")}</div>
        </div>
        <div className="videodeviceoptionscontainer">
          {videoDevices?.map((device: any) => (
            <button
              className="deviceselectionoption"
              key={device?.deviceId}
              onClick={() => handleCameraSelection(device)}
            >
              <div className="system-mic">{device?.label}</div>
              {device?.deviceId === selectedCamera?.deviceId && (
                <SelectionOptionIcon className="selected-icon" />
              )}
            </button>
          ))}
        </div>
      </div>
      {/* <div className="separator" />
      <div className="select-video-device-container1">
        <div className="virtual-background-title-bar1">
          <div className="virtual-background-icon-and-ti1">
            <BackgroundBlurIcon className="backgroundbluricon1" />              
            <div className="select-video-device">Virtual Background</div>
          </div>
          <div className="chooseimagearrowscontainer1">
            <DownArrow className="downarrowicon1" />
            <UpArrow className="uparrowicon1" />
          </div>
        </div>
        <div className="virtual-background-options-sec1">
          <div className="blur-background-options-sectio1">
            <div className="blur-background1">Blur Background</div>
            <div className="blur-background-options1">
              <button className="selectbackgroundthumbnail7">
                <img
                  className="selectbackgroundimage-icon7"
                  alt="selectbackgroundimage"
                  src="/selectbackgroundimage@2x.png"
                />
                <b className="selectbackgroundlabel7">Off</b>
              </button>
              <button className="selectbackgroundthumbnail7">
                <img
                  className="selectbackgroundimage-icon7"
                  alt="selectbackgroundimage"
                  src="/selectbackgroundimage1@2x.png"
                />
                <div className="selectbackgroundlabel8">{`Slight `}</div>
              </button>
              <button className="selectbackgroundthumbnail7">
                <img
                  className="selectbackgroundimage-icon7"
                  alt="selectbackgroundimage"
                  src="/selectbackgroundimage2@2x.png"
                />
                <div className="selectbackgroundlabel8">Max</div>
              </button>
            </div>
          </div>
          <div className="rectangle-group">
            <div className="frame-inner" />
            <b className="or1">OR</b>
            <div className="frame-inner" />
          </div>
          <div className="blur-background-options-sectio1">
            <div className="blur-background1">Choose Image</div>
            <div className="choose-image-options1">
              <button className="selectbackgroundthumbnail7">
                <img
                  className="selectbackgroundimage-icon7"
                  alt="selectbackgroundimage"
                  src="/selectbackgroundimage3@2x.png"
                />
                <div className="selectbackgroundlabel10">{`Slight `}</div>
              </button>
              <button className="selectbackgroundthumbnail7">
                <img
                  className="selectbackgroundimage-icon7"
                  alt="selectbackgroundimage"
                  src="/selectbackgroundimage4@2x.png"
                />
                <div className="selectbackgroundlabel10">{`Slight `}</div>
              </button>
              <button className="selectbackgroundthumbnail7">
                <img
                  className="selectbackgroundimage-icon7"
                  alt="selectbackgroundimage"
                  src="/selectbackgroundimage5@2x.png"
                />
                <div className="selectbackgroundlabel10">{`Slight `}</div>
              </button>
              <button className="selectbackgroundthumbnail7">
                <img
                  className="selectbackgroundimage-icon7"
                  alt="selectbackgroundimage"
                  src="/selectbackgroundimage3@2x.png"
                />
                <div className="selectbackgroundlabel10">{`Slight `}</div>
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default VideoDeviceSelection;
