import React, { useImperativeHandle, forwardRef } from 'react';
import { notification } from 'antd';
import "./Notification.scss";

interface NotificationRef {
    openNotification: (type: 'success' | 'error' | 'warning', message: string, description?: string) => void;
}
/**
 * 
 * @param ref ref to the component
 * @returns returns jsx to display the notifications/toast
 */
const NotificationComponent: React.ForwardRefRenderFunction<NotificationRef, {}> = (_, ref) => {
    const openNotification = (type: 'success' | 'error' | 'warning', message: string, description?: string) => {
        notification[type]({
            message: "",
            description: description,
            className: 'custom-notification', // Apply custom class
            placement: 'topRight',
          // duration: 2000,
            onClick: () => {
                // console.log('Notification Clicked!');
            },
        });
    };

    // Expose the openNotification function to the parent component
    useImperativeHandle(ref, () => ({
        openNotification,
    }));

    return <div></div>;
};

export default forwardRef(NotificationComponent);
