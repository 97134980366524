import { FunctionComponent, useEffect } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as SelectedMicDevice } from "../../Resources/InCallImages/DeviceSelection/SelectMicDeviceIcon.svg";
import { ReactComponent as SelectionOptionIcon } from "../../Resources/InCallImages/DeviceSelection/SelectionOptionIcon.svg";
import { ReactComponent as SelectedSpeaker } from "../../Resources/InCallImages/DeviceSelection/SelectSpeakerIcon.svg";
import { ReactComponent as BNCIcon } from "../../Resources/InCallImages/DeviceSelection/BackgroundNoiseCancellingIcon.svg";
import { setErrorNotification } from "../../Redux/Actions/NotificationAction";
import { store } from "../../Redux/store";
import { Switch } from "antd";
import proConfService from "../../Services/ProConfService";
import "./CallDeviceSelection.scss";
import { getTranslation } from "../../Resources/Localization/i18n";
// import "antd/dist/antd.min.css";

export type CallDeviceSelectionType = {
  className?: string;
  onClose?: () => void;
  isBNCEnabled: boolean;
  onToggleBNC: () => void;
};

const CallDeviceSelection: FunctionComponent<CallDeviceSelectionType> = ({
  className = "",
  onClose,
  isBNCEnabled,
  onToggleBNC,
}) => {
  const micDevices =
    useSelector((state: any) => state.InCallReducer.audioInputDevices) ||
    proConfService.getAudioInputDevices;
  const speakerDevices =
    useSelector((state: any) => state.InCallReducer.audioOutputDevices) ||
    proConfService.getAudioOutputDevices;
  const selectedMic = useSelector(
    (state: any) => state.ToolbarReducer.selectedMic
  );
  const selectedSpeaker = useSelector(
    (state: any) => state.ToolbarReducer.selectedSpeaker
  );
  const localParticipant = useSelector(
    (state: any) => state.ParticipantReducer.localParticipant
  );

  useEffect(() => {
    if (micDevices?.length === 0) {
      (async () => {
        await proConfService.getAudioInputDevices();
      })();
    }
    if (speakerDevices?.length === 0) {
      (async () => {
        await proConfService.getAudioOutputDevices();
      })();
    }
  }, []);

  // Restart the local mic track with updated deviceID
  const handleMicSelection = async (device: any) => {
    if (device?.deviceId && selectedMic?.deviceId !== device?.deviceId) {
      store.dispatch({ type: "SET_SELECTED_MIC", payload: device });
      try {
        const audioTrack = await localParticipant.audioTracks[0]?.restart({
          deviceId: { exact: device.deviceId },
        });
        if (!audioTrack) {
          setErrorNotification(getTranslation("micSwitchError"));
        }
      } catch (error) {
        console.log(error);
        setErrorNotification(getTranslation("micSwitchError"));
      }
    }
  };

  // Handle speaker device change
  const handleSpeakerSelection = async (device: any) => {
    if (device?.deviceId && selectedSpeaker?.deviceId !== device?.deviceId) {
      try {
        store.dispatch({ type: "SET_SELECTED_SPEAKER", payload: device });
        await proConfService.setAudioOutputDevice(device?.deviceId);
      } catch (error) {
        setErrorNotification(getTranslation("speakerSwitchError"));
        console.log(error);
      }
    }
  };

  return (
    <div className={`calldeviceselection ${className}`}>
      <div className="selectmicdevicecontainer">
        <div className="selectmicdevicetitlebar">
          <SelectedMicDevice className="selectmicicon" />
          <div className="select-mic-device">
            {getTranslation("selectMicDevice")}
          </div>
        </div>
        <div className="audiodeviceoptionscontainer">
          {micDevices?.map((device: any) => (
            <button
              className="deviceselectionoption2"
              key={device?.deviceId}
              onClick={() => handleMicSelection(device)}
            >
              <div className="system-mic2 ellipsis" title={device?.label}>
                {device?.label}
              </div>
              {device?.deviceId === selectedMic?.deviceId && (
                <SelectionOptionIcon className="selected-icon2" />
              )}
            </button>
          ))}
        </div>
      </div>
      <div className="separator1" />
      <div className="selectmicdevicecontainer">
        <div className="selectmicdevicetitlebar">
          <SelectedSpeaker className="selectmicicon" />
          <div className="select-mic-device">
            {getTranslation("selectSpeakerDevice")}
          </div>
        </div>
        <div className="audiodeviceoptionscontainer">
          {speakerDevices?.map((device: any) => (
            <button
              className="deviceselectionoption2"
              key={device?.deviceId}
              onClick={() => handleSpeakerSelection(device)}
            >
              <div className="system-mic2 ellipsis" title={device?.label}>
                {device?.label}
              </div>
              {device?.deviceId === selectedSpeaker?.deviceId && (
                <SelectionOptionIcon className="selected-icon2" />
              )}
            </button>
          ))}
        </div>
      </div>
      <div className="separator1" />
      <div className="backgroundblurcontainer">
        <div className="backgroundblurtitleandicon">
          <BNCIcon className="backgroundnoiseicon" />
          <div className="select-mic-device">{getTranslation("bnc")}</div>
        </div>
        <Switch
          className="switch2"
          style={{ width: 26 }}
          checked={isBNCEnabled}
          onChange={onToggleBNC}
        />
      </div>
    </div>
  );
};

export default CallDeviceSelection;
