import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Input } from "antd";
import { useSelector } from "react-redux";
import { Track } from "proconf-web-sdk";

//Custom Components
import CustomDropDown from "../../Common Components/CustomDropDown/CustomDropdown";
import Logout from "../../Common Components/LogoutMenu/Logout";
import PortalPopup from "../../Common Components/PortalPopup/PortalPopup";
import LoadingSpinner from "../../Common Components/LoadingSpinner/LoadingSpinner";
import CustomAvatar from "../../Common Components/CustomAvatar/CustomAvatar";
import ProfileMenu from "../../Common Components/ProfileMenu/ProfileMenu";

//Translation
import { getTranslation } from "../../Resources/Localization/i18n";

//Assets
import ProConfLogo from "../../Resources/Images/ProConfLogo@2x.png";
import { ReactComponent as Arrowback } from "../../Resources/Images/arrowback.svg";
import { ReactComponent as MicOff } from "../../Resources/Images/IconMicOffSmall.svg";
import { ReactComponent as MicOn } from "../../Resources/Images/IconMicOnSmall.svg";
import { ReactComponent as VideoOn } from "../../Resources/Images/IconVideoOnSmall.svg";
import { ReactComponent as VideoOff } from "../../Resources/Images/IconVideoOffSmall.svg";
import { ReactComponent as SpeakerOff } from "../../Resources/Images/ic_speaker_off.svg";

//Constants
import {
  CLEAR_DEVICES,
  CLEAR_NOTIFICATIONS,
  ON_MIC_MUTE_UNMUTE,
  ON_VIDEO_MUTE_UNMUTE,
} from "../../Redux/ReduxConstants";
import { CONFIGURATIONS } from "../../Constants/AppConstants";

//Store
import { store } from "../../Redux/store";

//Services
import Proconfservice from "../../Services/ProConfService";

//Styles
import "./MeetingPreview.scss";
import { setErrorNotification } from "../../Redux/Actions/NotificationAction";
import { useDispatch } from "react-redux";
import { delay } from "../../Utility/Utils";

interface MeetingPreviewProps {
  isJoinFlow: boolean;
  isCreateMeeting: boolean;
  onBackClick: () => void;
  onStartClick: (roomName: string) => void;
  onLogoutClick: () => void;
  enableStartMeetingButton: boolean;
  roomNameToJoin: string;
}

/**
 * @description User can select the devices like audio, video, speakers etc
 *  from this page before starting the meeting
 * @return {FunctionComponent} jsx component to render a Meeting Preview page
 */
const MeetingPreview: React.FC<MeetingPreviewProps> = ({
  isJoinFlow,
  isCreateMeeting,
  onBackClick,
  onStartClick,
  onLogoutClick,
  enableStartMeetingButton,
  roomNameToJoin,
}) => {
  /* #region Variable Declaration */

  //useSelector cannot be called contionally, so used getState method here
  const userName =
    store.getState()?.LoginReducer.userName ||
    store.getState()?.RoomReducer.userName;

  const [roomName, setRoomName] = useState(roomNameToJoin);

  const micstate = useSelector((state: any) => state.ToolbarReducer.audioMute);
  const videostate = useSelector(
    (state: any) => state.ToolbarReducer.videoMute
  );
  const isInitDone = useSelector(
    (state: any) => state.ProConfReducer.isInitDone
  );

  // State to manage which components are visible
  const { configurations } = useSelector((state: any) => state.LoginReducer);

  const [enableStartButton, setEnableStartButton] = useState(
    enableStartMeetingButton
  );
  const [selectedMic, setSelectedMic] = useState({ deviceId: "", label: "" });
  const [selectedCamera, setSelectedCamera] = useState({
    deviceId: "",
    label: "",
  });
  const [selectedSpeaker, setSelectedSpeaker] = useState({
    deviceId: "",
    label: "",
  });
  const [micMute, setMicMute] = useState(micstate);
  const [videoMute, setVideoMute] = useState(videostate);
  const [localAudiotrack, setLocalAudiotrack] = useState({} as Track);
  const [localVideotrack, setLocalVideotrack] = useState({} as Track);

  const [isLoading, setLoading] = useState(false);
  const [isLogoutPopupOpen, setLogoutPopupOpen] = useState(false);
  const [isFromLink, setIsFromLink] = useState(false);
  const [enableControls, setEnableControls] = useState(false);

  const audioInputDevices = useSelector(
    (state: any) => state.InCallReducer.audioInputDevices
  );
  const audioOutputDevices = useSelector(
    (state: any) => state.InCallReducer.audioOutputDevices
  );
  const videoDevices = useSelector(
    (state: any) => state.InCallReducer.videoDevices
  );

  const audioPermission = useSelector(
    (state: any) => state.InCallReducer.audioPermission
  );
  const videoPermission = useSelector(
    (state: any) => state.InCallReducer.videoPermission
  );

  const dispatch = useDispatch();

  // Get previously selected devices by the user(in case of page refresh)
  const { userCamera, userMic, userSpeaker } = useSelector((state: any) => ({
    userCamera: state.ToolbarReducer.selectedCamera,
    userSpeaker: state.ToolbarReducer.selectedSpeaker,
    userMic: state.ToolbarReducer.selectedMic,
  }));

  //Ref
  const profileRef = useRef<HTMLButtonElement>(null);
  /* #endregion */

  useEffect(() => {
    // Check if user comes from invite link and disable roomname field
    if (roomName && roomName.trim().length > 0) {
      setIsFromLink(true);
    }
    setLoading(true);

    // Check if init is done and then only create tracks
    const timer = setInterval(async () => {
      if (isInitDone === true) {
        clearInterval(timer);
        Proconfservice.handleMediaEvents();
        await setInitialTracks();
      }
    }, 1000);

    return () => {
      // unmount
      setIsFromLink(false);
      clearInterval(timer);
      clearTracks(true, true);
    };
  }, []);

  useMemo(() => {
    if (CONFIGURATIONS.VIDEO_CALL in configurations && videoDevices) {
      const matchingDevice = videoDevices.find(
        (device: MediaDeviceInfo) => device?.label === userCamera?.label
      );
      if (!matchingDevice) {
        setSelectedCamera(videoDevices?.[0]);
      } else {
        setSelectedCamera(userCamera);
      }
    } else {
      setLocalVideotrack({} as Track);
    }
  }, [configurations, videoDevices]);

  useMemo(() => {
    if (audioInputDevices) {
      const matchingDevice = audioInputDevices.find(
        (device: MediaDeviceInfo) => device?.label === userMic?.label
      );
      if (!matchingDevice) {
        setSelectedMic(audioInputDevices?.[0]);
      } else {
        setSelectedMic(userMic);
      }
    } else {
      setLocalAudiotrack({} as Track);
    }
  }, [audioInputDevices]);

  useMemo(() => {
    if (audioOutputDevices) {
      const matchingDevice = audioOutputDevices.find(
        (device: MediaDeviceInfo) => device?.label === userSpeaker?.label
      );
      if (!matchingDevice) {
        setSelectedSpeaker(audioOutputDevices?.[0]);
      } else {
        setSelectedSpeaker(userSpeaker);
      }
    }
  }, [audioOutputDevices]);

  /**
   * @method setTracks
   * @description Set local tracks everytime a new track is selected from dropdown,
   *  which can be passed while creating room
   */
  const setTracks = async (audioD: any, videoD?: any) => {
    let cameraDevice = videoD || null;
    let micdevice = audioD;
    setEnableControls(false);
    setEnableStartButton(false);
    const deviceObj: { micDeviceId?: string; cameraDeviceId?: string } = {};
    if (audioD) {
      deviceObj.micDeviceId = micdevice?.deviceId;
    }
    if (videoD && CONFIGURATIONS.VIDEO_CALL in configurations) {
      deviceObj.cameraDeviceId = cameraDevice ? cameraDevice?.deviceId : null;
    }

    // Clear previous tracks
    await clearTracks(!!audioD, !!videoD);
    await delay(1000);
    try {
      const localtracks = await Proconfservice.createTracks(
        deviceObj,
        !!audioD,
        !!videoD
      );
      localtracks?.map((track: Track) => {
        if (track?.kind === "audio" && !!audioD) {
          setLocalAudiotrack(track);
          document
            ?.getElementById("previewwindowsettings")
            ?.appendChild(track?.attach() as HTMLAudioElement);
          if (micMute) {
            track?.disable();
          }
        } else if (
          track?.kind === "video" &&
          CONFIGURATIONS.VIDEO_CALL in configurations &&
          !!videoD
        ) {
          setLocalVideotrack(track);
          track?.attach(
            document.getElementById("video-preview") as HTMLVideoElement
          );
          if (videoMute) {
            track?.disable();
          }
        }
        setEnableStartButton(true);
        setEnableControls(true);
      });
    } catch (err) {
      console.error(err);
      setEnableStartButton(true);
      setEnableControls(true);
      setErrorNotification(getTranslation("deviceInUse"));
      await delay(2000);
      dispatch({ type: CLEAR_NOTIFICATIONS });
    }
  };

  /**
   * @method clearTracks
   */
  const clearTracks = async (
    clearAudioTrack: boolean,
    clearVideoTrack: boolean
  ) => {
    if (clearAudioTrack && localAudiotrack.sid) {
      await localAudiotrack?.stop();
    }
    if (clearVideoTrack && localVideotrack.sid) {
      await localVideotrack?.stop();
    }
  };

  /**
   * Function to fetch devices list on initial mount
   */
  const setInitialTracks = async () => {
    if (isInitDone && !localAudiotrack.sid && !localVideotrack.sid) {
      if (CONFIGURATIONS.VIDEO_CALL in configurations) {
        const videoPermission = await Proconfservice.isBrowserPermissionAllowed(
          "video"
        );
        if (videoPermission && isInitDone) {
          if (videoDevices.length === 0) {
            await Proconfservice.getVideoDevices();
          } else {
            setTimeout(async () => await setTracks(null, userCamera), 5000);
          }
        }
      }
      const micPermission = await Proconfservice.isBrowserPermissionAllowed(
        "audio"
      );
      if (micPermission && isInitDone) {
        if (audioInputDevices.length === 0) {
          await Proconfservice.getAudioOutputDevices();
          await Proconfservice.getAudioInputDevices();
        } else {
          // need to wait for init on page refresh
          setTimeout(async () => await setTracks(userMic, null), 5000);
        }
      }
      setLoading(false);
    }
  };

  useMemo(async () => {
    // @ts-ignore
    if (selectedMic && selectedMic?.deviceId && isInitDone) {
      store.dispatch({ type: "SET_SELECTED_MIC", payload: selectedMic });
      await setTracks(selectedMic, null);
    }
  }, [selectedMic]);

  useMemo(async () => {
    // @ts-ignore
    if (selectedSpeaker && selectedSpeaker?.deviceId && isInitDone) {
      store.dispatch({ type: "SET_SELECTED_SPEAKER", payload: selectedSpeaker });
      await Proconfservice.setAudioOutputDevice(selectedSpeaker?.deviceId);
    }
  }, [selectedSpeaker]);

  useMemo(async () => {
    // @ts-ignore
    if (
      selectedCamera &&
      selectedCamera?.deviceId &&
      isInitDone &&
      videoPermission
    ) {
      store.dispatch({ type: "SET_SELECTED_CAMERA", payload: selectedCamera });
      await setTracks(null, selectedCamera);
    }
  }, [selectedCamera, videoPermission]);

  /* #region Event Handlers */

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    let value = e.target.value;
    switch (type) {
      case "roomName":
        setRoomName(value);
        break;
    }
  };

  /**
   * on change selection of media devices
   */
  const onChangeMicroPhone = (e: any, option: any) => {
    setSelectedMic(option);
  };

  const onChangeCamera = (e: any, option: any) => {
    setSelectedCamera(option);
  };

  const onChangeSpeaker = (e: any, option: any) => {
    setSelectedSpeaker(option);
    store.dispatch({ type: "SET_SELECTED_SPEAKER", payload: option });
  };

  const toggleMic = async () => {
    if (micMute) {
      await Proconfservice.unmuteAudio();
    } else {
      await Proconfservice.muteAudio();
    }
    setMicMute(!micMute);
    dispatch({ type: ON_MIC_MUTE_UNMUTE });
  };

  const toggleVideo = async () => {
    if (videoMute) {
      await Proconfservice.unmuteVideo();
    } else {
      await Proconfservice.muteVideo();
    }
    setVideoMute(!videoMute);
    store.dispatch({ type: ON_VIDEO_MUTE_UNMUTE });
  };

  // Re-attach video track after unmute video
  useEffect(() => {
    const videoElement = document.getElementById(
      "video-preview"
    ) as HTMLVideoElement;
    if (!videoMute && localVideotrack?.sid && videoElement?.paused)
      localVideotrack?.attach(videoElement);
  }, [localVideotrack, videoMute]);

  const onBackButtonClick = async () => {
    await clearTracks(true, true);
    dispatch({ type: CLEAR_DEVICES });
    onBackClick();
  };

  const onStart = async () => {
    if (roomName) {
      setEnableStartButton(false);
      onStartClick(roomName);
    }
  };

  /**
   * onclick for profile menu
   * consists option to logout from the system
   */
  const toggleLogoutPopup = useCallback(() => {
    setLogoutPopupOpen(!isLogoutPopupOpen);
  }, [isLogoutPopupOpen]);

  /* #endregion */

  /* #region Renderers */
  /**
   *
   * @returns jsx for logout dropdown
   * will be displayed onclick of profile
   */
  const renderLogoutDropdown = () => {
    return (
      <PortalPopup
        relativeLayerRef={profileRef}
        placement="Bottom right"
        onOutsideClick={toggleLogoutPopup}
      >
        <Logout onLogoutClick={onLogoutClick} />
      </PortalPopup>
    );
  };

  /**
   *
   * @returns jsx to display roomname field and
   * device selections
   */
  const renderDevices = () => {
    return (
      <div className="entermeetingdetails">
        <div className="formfields">
          <div className="enterroomname">
            <div className="inputfield">
              <div className="titletext">{getTranslation("roomName")}</div>
              <Input
                className="inputfield1"
                size="large"
                onChange={(e) => handleInputChange(e, "roomName")}
                name="roomName"
                placeholder={getTranslation("enterRoomName")}
                type="text"
                required
                maxLength={50}
                value={roomName || ""}
                disabled={isFromLink}
              />
            </div>
          </div>
          <div className="chooseDevices">
            <div className="titletext1">{getTranslation("chooseDevices")}</div>
            <div className="inputdropdown">
              <div className="label1">{getTranslation("speaker")}</div>

              <CustomDropDown
                options={audioOutputDevices}
                selectedOption={selectedSpeaker}
                onSelectChange={onChangeSpeaker}
              />
            </div>
            <div className="inputdropdown">
              <div className="label1">{getTranslation("microphone")}</div>
              <CustomDropDown
                options={audioInputDevices}
                selectedOption={selectedMic}
                onSelectChange={onChangeMicroPhone}
              />
            </div>
            {CONFIGURATIONS.VIDEO_CALL in configurations && (
              <div className="inputdropdown">
                <div className="label1">{getTranslation("camera")}</div>

                <CustomDropDown
                  options={videoDevices}
                  selectedOption={selectedCamera}
                  onSelectChange={onChangeCamera}
                />
              </div>
            )}
          </div>
        </div>
        <div className="buttonprimary-wrapper">
          <button
            className="buttonprimary"
            disabled={
              !roomName.trim() ||
              !enableStartButton || !enableControls ||
              (!localVideotrack?.sid && !localAudiotrack?.sid)
            }
            onClick={onStart}
          >
            <b className="buttonlabel">
              {isJoinFlow || !isCreateMeeting
                ? getTranslation("join")
                : getTranslation("start")}
            </b>
          </button>
        </div>
      </div>
    );
  };

  const renderVideoPreview = () => {
    return (
      <div className="videopreview">
        <div className="titletext">{getTranslation("videoPreview")}</div>
        <div
          className={
            videoMute || !videoPermission
              ? "previewwindowsettings"
              : "previewwindowsettings video-fit"
          }
        >
          <div className="outerpreview">
            {videoMute || !videoPermission ? (
              <div className={"avatar-container"}>
                <CustomAvatar name={userName} className="container" />
              </div>
            ) : (
              <video className={"previewwindow"} id="video-preview" />
            )}
          </div>
          <div className="controls">
            <div className="callaudiovideocontrols">
              <button
                className={
                  localAudiotrack &&
                  localAudiotrack?.sid &&
                  enableControls &&
                  audioPermission
                    ? "controloptionsmall"
                    : "controloptionsmall disabled-icon"
                }
                onClick={toggleMic}
              >
                {micMute ? (
                  <MicOff
                    className={
                      localAudiotrack && localAudiotrack?.sid
                        ? "controliconssmall"
                        : "controliconssmall disabled-icon"
                    }
                  />
                ) : (
                  <MicOn
                    className={
                      localAudiotrack && localAudiotrack?.sid
                        ? "controliconssmall"
                        : "controliconssmall disabled-icon"
                    }
                  />
                )}
                <div className="already-have-an">{getTranslation("mic")}</div>
              </button>
              <button
                className={
                  localVideotrack &&
                  localVideotrack?.sid &&
                  enableControls &&
                  videoPermission
                    ? "controloptionsmall"
                    : "controloptionsmall disabled-icon"
                }
                onClick={toggleVideo}
              >
                {videoMute ? (
                  <VideoOff
                    className={
                      localVideotrack && localVideotrack?.sid
                        ? "controliconssmall"
                        : "controliconssmall disabled-icon"
                    }
                  />
                ) : (
                  <VideoOn
                    className={
                      localVideotrack && localVideotrack?.sid
                        ? "controliconssmall"
                        : "controliconssmall disabled-icon"
                    }
                  />
                )}
                <div className="already-have-an">{getTranslation("video")}</div>
              </button>
              <button className="controloptionsmall2">
                <SpeakerOff className="controliconssmall" />

                <div className="already-have-an">
                  {getTranslation("speaker")}
                </div>
              </button>
              <button className="controloptionsmall2">
                <SpeakerOff className="controliconssmall" />
                <div className="already-have-an">
                  {getTranslation("speaker")}
                </div>
              </button>
            </div>
            {/* <div className="backgroundblur">
							<div className="bgblur-parent">
								<div className="bgblur">
									<img className="vector-icon2" alt="" src={Vector1} />
								</div>
								<div className="already-have-an4">
									{getTranslation("bgBlur")}
								</div>
							</div>
							<Switch
								className="switch2"
								style={{
									width: 26,
								}}
							/>
						</div> */}
          </div>
        </div>
      </div>
    );
  };
  /* #endregion */

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className="create-meeting">
        <div className="whitebannerproconf">
          <header className="header">
            <div className="nav-items">
              <img className="logo-icon" alt="ProConf Logo" src={ProConfLogo} />
              {!isJoinFlow && (
                <ProfileMenu
                  className="profile1"
                  buttonRef={profileRef}
                  toggleLogoutPopup={toggleLogoutPopup}
                />
              )}
            </div>
          </header>
        </div>
        <div className="previewmeetingsection">
          <div className="createmeetingsection">
            <div className="createmeetinglist">
              <div className="meetinglabel">
                <button className="arrowback">
                  <Arrowback
                    className={
                      enableControls
                        ? "vector-icon1"
                        : "vector-icon1 disabled-icon"
                    }
                    onClick={onBackButtonClick}
                  />
                </button>
                <b className="meetingtext">
                  {isJoinFlow || !isCreateMeeting
                    ? getTranslation("joinMeetingText")
                    : getTranslation("createMeeting")}
                </b>
              </div>
            </div>

            <div className="previewsection">
              {renderDevices()}
              {CONFIGURATIONS.VIDEO_CALL in configurations &&
                renderVideoPreview()}
            </div>
          </div>
        </div>
      </div>

      {isLogoutPopupOpen && renderLogoutDropdown()}
    </>
  );
};

export default MeetingPreview;
